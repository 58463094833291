<template>
  <BaseRichText
    v-editable="blok"
    :class="[customMargin, textColor, narrowText, textAlignment]"
    :rich-text-template="blok.richtext"
  />
</template>

<script lang="ts" setup>
import type { SbRichtextStoryblok } from '@/types'

interface Props {
  blok: SbRichtextStoryblok
}

const props = defineProps<Props>()

const customMargin = computed(() => `mb-${props.blok?.custom_margin}` ?? '')
const textColor = computed(() => `text-${props.blok?.color?.color_name}` ?? '')
const narrowText = computed(() => (props.blok?.narrow ? 'text-narrow' : ''))
const textAlignment = computed(() =>
  props.blok.text_align ? `text-align--${props.blok.text_align}` : '',
)
</script>

<style lang="scss" scoped>
.text-narrow {
  max-width: 660px;
  margin: 0 auto;
}

.text-align {
  &--left {
    text-align: left;
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }
}
</style>
